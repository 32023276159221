import React, { Component } from 'react';
import './App.css';
import Sidebar from './components/sidebar'
import Introduction from './components/introduction'
import About from './components/about'
import Timeline from './components/timeline'
import { PopupWidget } from "react-calendly"
import { SantoshCalendar } from './components/SantoshCalendar'
import { displayDesktop } from './components/Header';
import { MyContactForm } from './components/MyContactForm';
import { FormControlUnstyled } from './components/mytext';
class App extends Component {
  render() {
    return (

      <div id="colorlib-page">
        <div id="container-wrap">
         	<Sidebar></Sidebar>*
				<div id="colorlib-main">
          <Introduction></Introduction>
          <About></About>
					<Timeline></Timeline>

              <PopupWidget
               id="xyz"
               color="#00a2ff"
               text="Schedule Time with me"
               textColor="#ffffff"
               rootElement={document.getElementById("root")}
               url="https://calendly.com/sktalluri/30min"/>

          	</div>
      	</div>
      </div>
    );
  }
}

export default App;
